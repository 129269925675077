@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/sourcesanspro-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/sourcesanspro-regular-webfont.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/sourcesanspro-semibold-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/sourcesanspro-semibold-webfont.woff") format("woff");
  font-weight: 600;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Source Sans Pro", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.85);
  background-color: #f3f3f3;
  height: 100%;
}

html,
#root {
  height: 100%;
}

/*ONLY FOR TABLE IN STANDARD, dear god why is this even a thing*/

.st_border_normal {
  border-right: 2px solid #fc5d5d !important;
}

.st_border_ebook {
  border-right: 2px solid #00d14d !important;
}

.st_border_audiobook {
  border-right: 2px solid #546bff !important;
}

.st_border_undefined {
  border-right: 2px solid #8a8a8a !important;
}

.st_row {
  font-size: 0.9em;
}

.st_row_icon {
  font-size: 1.2em !important;
}
